<template>
  <div style="height: 350px; display: flex; align-items: center; justify-content: center;">
    <div class="card">
      <div class="card-body">
        <p class="m-0 text-center">リダイレクト中</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(["singleSignOnParam"])
  },
  created(){
    const j2URL = process.env.VUE_APP_J2_URL;
    const bizURL = process.env.VUE_APP_BIZ_URL;
    let url = window.location.href;
    url = url.replace(bizURL, j2URL);
    if(url.indexOf('?') > -1){
      url += '&' + this.singleSignOnParam;
    }else{
      url += '?' + this.singleSignOnParam;
    }
    this.$store.dispatch('redirectSingleSignOnLink', {ssoLink: url, isCurrentTab: true});
  }
}
</script>

<style>

</style>